<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Administration, Tillgångar" />
      <div class="before-content">
        <div class="d-flex justify-content-start">
          <button
            type="button"
            class="btn btn-outline-secondary"
            v-if="!showForm"
            @click="showForm = true"
          >
            <i class="bi bi-plus-circle"></i> Ny tillgång
          </button>
        </div>
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p class="m-0" v-for="(error, index) in errors" :key="index">
            {{ error }}
          </p>
        </div>
        <div class="row g-3 p-3 mt-3 extra-data" v-if="showForm">
          <div class="col-md-3">
            <input
              type="text"
              class="form-control"
              placeholder="Namn"
              v-model="name"
            />
          </div>
          <div class="col-md-3">
            <datepicker
              class="form-control"
              placeholder="Välj inlösendatum"
              :locale="local"
              :lower-limit="lowerLimit"
              v-model="redemptionDate"
            />
          </div>
          <div class="col-md-6">&nbsp;</div>
          <div class="col-md-3">
            <select class="form-select" v-model="emittent">
              <option :value="null">Välj emittent</option>
              <option
                :value="emittent"
                v-for="(emittent, emittentKey) in emittents"
                :key="emittentKey"
              >
                {{ emittent.emittent }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="benefit">
              <option :value="null">Välj förmånsrättskategori</option>
              <option
                :value="benefit"
                v-for="(benefit, benefitKey) in benefits"
                :key="benefitKey"
              >
                {{ benefit.fmr }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="forvaring">
              <option :value="null">Välj förvaring</option>
              <option
                :value="forvaring"
                v-for="(forvaring, forvaringKey) in storage"
                :key="forvaringKey"
              >
                {{ forvaring.forvaring }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="hallbarhet">
              <option :value="null">Välj hållbarhet</option>
              <option
                :value="hallbarhet"
                v-for="(hallbarhet, hallbarhetKey) in sustainability"
                :key="hallbarhetKey"
              >
                {{ hallbarhet.hallbarhet }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="likviditet">
              <option :value="null">Välj likviditet</option>
              <option
                :value="likviditet"
                v-for="(likviditet, likviditetKey) in liquidity"
                :key="likviditetKey"
              >
                {{ likviditet.likviditet }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="category">
              <option :value="null">Välj tillgångskategori</option>
              <option
                :value="category"
                v-for="(category, categoryKey) in categories"
                :key="categoryKey"
              >
                {{ category.kategori }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="omr">
              <option :value="null">Välj tillgångsområde</option>
              <option
                :value="omr"
                v-for="(omr, omrKey) in omrade"
                :key="omrKey"
              >
                {{ omr.omrade }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="accessType">
              <option :value="null">Välj tillgångsslag</option>
              <option
                :value="accessType"
                v-for="(accessType, accessTypeKey) in accessTypes"
                :key="accessTypeKey"
              >
                {{ accessType.slag }}
              </option>
            </select>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button
              type="button"
              class="btn btn-success"
              @click="handleSubmit()"
            >
              <i class="bi bi-save"></i> Spara
            </button>
            <button
              type="button"
              class="btn btn-secondary mr-3"
              v-if="showForm"
              @click="reset()"
            >
              <i class="bi bi-x-circle"></i> Avbryt
            </button>
          </div>
        </div>
      </div>
      <div class="content p-3">
        <table class="table" v-if="assets && assets.length > 0">
          <thead>
            <tr>
              <th scope="col">Tillgångar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(asset, index) in assets" :key="index">
              <td>{{ asset.namn }}</td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-secondary" role="alert" v-else>
          <p class="m-0">Ingen tillgång hittades</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
// import FormInput from "@/components/Inmatning.vue";
import { mapState } from "vuex";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import { sv } from "date-fns/locale";
import { ref } from "vue";
export default {
  name: "Tillgangar",
  data() {
    return {
      local: sv,
      lowerLimit: ref(new Date()),
      showForm: false,
      name: null,
      redemptionDate: null,
      emittent: null,
      benefit: null,
      forvaring: null,
      hallbarhet: null,
      likviditet: null,
      category: null,
      omr: null,
      accessType: null,
      errors: [],
    };
  },

  computed: {
    ...mapState({
      assets: (state) => state.assets.assets,
      emittents: (state) => state.emittent.emittents,
      benefits: (state) => state.fmr.benefits,
      storage: (state) => state.forvaring.storage,
      sustainability: (state) => state.hallbarhet.sustainability,
      liquidity: (state) => state.likviditet.liquidity,
      categories: (state) => state.kategori.categories,
      omrade: (state) => state.omrade.omrade,
      accessTypes: (state) => state.slag.accessTypes,
    }),
  },

  components: { Sidebar, Navbar, Datepicker },

  mounted() {
    this.$store.dispatch("assets/fetchAssets");
    this.$store.dispatch("emittent/fetchEmittent");
    this.$store.dispatch("fmr/fetchFMR");
    this.$store.dispatch("forvaring/fetchStorage");
    this.$store.dispatch("hallbarhet/fetchSustainability");
    this.$store.dispatch("likviditet/fetchLiquidity");
    this.$store.dispatch("kategori/fetchCategory");
    this.$store.dispatch("omrade/fetchOmrade");
    this.$store.dispatch("slag/fetchSlag");
  },

  methods: {
    async handleSubmit() {
      this.errors = [];
      if (!this.validateForm()) {
        let response = await this.$store.dispatch("assets/addAssets", {
          name: this.name,
          redemptionDate: this.redemptionDate
            ? moment(this.redemptionDate).format("YYYY-MM-DD")
            : null,
          omradesID: this.omr.omradesID,
          slagID: this.accessType.slagID,
          fmrID: this.benefit.fmrID,
          kategoriID: this.category.kategoriID,
          forvaringsID: this.forvaring.forvaringsID,
          emittentID: this.emittent.emittentID,
          hallbarhetsID: this.hallbarhet.hallbarhetsID,
          likviditetsID: this.likviditet.likviditetsID,
        });

        if (response) {
          this.reset();
        }
      }
    },

    validateForm() {
      let hasError = false;
      if (!this.name) {
        hasError = true;
        this.errors.push("Ange namn");
      }
      // if (!this.redemptionDate) {
      //   hasError = true;
      //   this.errors.push("Du måste välja inlösendatum");
      // }
      if (!this.emittent) {
        hasError = true;
        this.errors.push("Välj emittent");
      }
      if (!this.benefit) {
        hasError = true;
        this.errors.push("Välj förmånsrättskategori");
      }
      if (!this.forvaring) {
        hasError = true;
        this.errors.push("Välj förvaring");
      }
      if (!this.hallbarhet) {
        hasError = true;
        this.errors.push("Välj hållbarhet");
      }
      if (!this.likviditet) {
        hasError = true;
        this.errors.push("Välj likviditet");
      }
      if (!this.category) {
        hasError = true;
        this.errors.push("Välj tillgångskategori");
      }
      if (!this.omr) {
        hasError = true;
        this.errors.push("Välj tillgångsområde");
      }
      if (!this.accessType) {
        hasError = true;
        this.errors.push("Välj tillgångssalg");
      }
      return hasError;
    },

    reset() {
      this.errors = [];
      this.showForm = false;
      this.name = null;
      this.redemptionDate = null;
      this.emittent = null;
      this.benefit = null;
      this.forvaring = null;
      this.hallbarhet = null;
      this.likviditet = null;
      this.category = null;
      this.omr = null;
      this.accessType = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-data {
  background: #f1f1f1;
}
</style>
