<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Start" />
      <div class="content p-3"></div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: { Sidebar, Navbar },
};
</script>

<style></style>
