import { $axios } from "@/axios.config";

const state = {
  assets: [],
};

const mutations = {
  ADD_ASSETS(state, payload) {
    state.assets = payload;
  },
};

const actions = {
  // get.php?type=tillgangsomrade
  fetchAssets({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=tillgang", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("ADD_ASSETS", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php ? type = addTillgang, skicka med POST- variabler namn, inlosendatum och sedan ska man välja omradesID, slagID, fmrID, kategoriID, forvaringsID, emittentID, hallbarhetsID och likviditetsID
  addAssets({ rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("namn", data.name);
      formData.append("inlosendatum", data.redemptionDate);
      formData.append("omradesID", data.omradesID);
      formData.append("slagID", data.slagID);
      formData.append("fmrID", data.fmrID);
      formData.append("kategoriID", data.kategoriID);
      formData.append("forvaringsID", data.forvaringsID);
      formData.append("emittentID", data.emittentID);
      formData.append("hallbarhetsID", data.hallbarhetsID);
      formData.append("likviditetsID", data.likviditetsID);
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addTillgang", formData)
        .then((response) => {
          console.log(response);
          if (response.data.status == "ok") {
            dispatch("fetchAssets");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
