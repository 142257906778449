import { $axios } from "@/axios.config";

const state = {
  accessTypes: [],
};

const mutations = {
  ADD_SLAG(state, payload) {
    state.accessTypes = payload;
  },
};

const actions = {
  // get.php?type=tillgangsslag
  fetchSlag({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=tillgangsslag", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("ADD_SLAG", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addTillgangsslag, skicka med POST-variabel slag
  addSlag({ rootState, dispatch }, slag) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("slag", slag);
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addTillgangsslag", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            dispatch("fetchSlag");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
