import { $axios } from "@/axios.config";

// State objects
const state = {};

// Mutations
const mutations = {};

// Actions
const actions = {
  authLogin({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("user", data.email);
      formData.append("pass", data.password);
      $axios
        .post(rootState.baseUrl + "/auth.php", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  authLogout({ rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/logout.php", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
