const subscribe = (store) => {
  store.subscribe((mutation) => {
    if (mutation.type == "LOGIN_AUTH") {
      localStorage.setItem("_fm_apiKey", JSON.stringify(mutation.payload.key));
      localStorage.setItem(
        "_fm_realname",
        JSON.stringify(mutation.payload.realname)
      );
    }

    if (mutation.type == "LOGOUT_AUTH") {
      localStorage.removeItem("_fm_apiKey");
    }
  });
};

export default subscribe;
