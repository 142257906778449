<template>
  <table class="table">
    <thead>
      <tr>
        <th>{{ kolumn }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(rad, id) in rader" :key="'rad' + id">
        <td>{{ rad }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Admintabell",
  props: ["kolumn", "rader"],
};
</script>

<style lang="scss" scoped></style>
