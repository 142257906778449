import { createStore } from "vuex";
import subscribe from "./modules/subscribe.store";
import auth from "./modules/auth.store";
import emittent from "./modules/administration/emittent.store";
import fmr from "./modules/administration/fmr.store";
import forvaring from "./modules/administration/forvaring.store";
import hallbarhet from "./modules/administration/hallbarhet.store";
import likviditet from "./modules/administration/likviditet.store";
import kategori from "./modules/administration/kategori.store";
import omrade from "./modules/administration/omrade.store";
import slag from "./modules/administration/slag.store";
import assets from "./modules/administration/assets.store";
import transaction from "./modules/transaction.store";
import notes from "./modules/noteringar.store";
import innehav from "./modules/innehav.store";
import report from "./modules/report.store";

export default createStore({
  state: {
    baseUrl: process.env.VUE_APP_URL,
    apiKey: JSON.parse(localStorage.getItem("_fm_apiKey")) || null,
    realname: JSON.parse(localStorage.getItem("_fm_realname")) || null,
    authenticated: JSON.parse(localStorage.getItem("_fm_apiKey"))
      ? true
      : false,
    parent: JSON.parse(localStorage.getItem("_fm_parent")) || null,
    child: JSON.parse(localStorage.getItem("_fm_child")) || null,
  },

  plugins: [subscribe],

  modules: {
    auth,
    emittent,
    fmr,
    forvaring,
    hallbarhet,
    likviditet,
    omrade,
    kategori,
    slag,
    assets,
    transaction,
    notes,
    innehav,
    report,
  },

  mutations: {
    LOGIN_AUTH(state, payload) {
      state.authenticated = payload.key ? true : false;
      state.apiKey = payload.key;
      state.realname = payload.realname;
    },

    LOGOUT_AUTH(state) {
      state.apiKey = null;
      state.authenticated = false;
      state.realname = null;
    },

    SET_MENU(state, payload) {
      if (state.parent === payload) {
        localStorage.removeItem("_fm_parent");
        state.parent = null;
      } else {
        state.parent = payload;
      }
    },
  },

  actions: {},
});
