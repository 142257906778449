import { $axios } from "@/axios.config";

const state = {
  innehavs: [],
};

const mutations = {
  FETCH_INNEHAVS(state, payload) {
    state.innehavs = payload;
  },
};

const actions = {
  fetchInnehavs({ commit, rootState }, date) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      formData.append("datum", date);
      $axios
        .post(rootState.baseUrl + "/get.php?type=innehav", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_INNEHAVS", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addInnehav({ rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addInnehav", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchInnehavs");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
