<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Noteringar" />
      <div class="before-content">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p class="m-0" v-for="(error, index) in errors" :key="index">
            {{ error }}
          </p>
        </div>
        <inmatning :data="fields" icon="noteringar" @save="handleSubmit()" />
      </div>
      <div class="content p-3">
        <table class="table" v-if="notes && notes.length > 0">
          <thead>
            <tr>
              <th scope="col">Tillgångar</th>
              <th scope="col">Datum</th>
              <th scope="col">Värde</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(note, index) in notes" :key="index">
              <td>{{ findAsset(note.tillgangsID) }}</td>
              <td>{{ note.datum }}</td>
              <td>{{ note.varde }}</td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-secondary" role="alert" v-else>
          <p class="m-0">Inga noteringar hittades</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Inmatning from "@/components/Inmatning.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: { Sidebar, Navbar, Inmatning },
  data() {
    return {
      kolumn: "Tillgångar",
      fields: [
        {
          label: "Välj tillgång",
          type: "select",
          name: "access",
          options: [],
          value: null,
        },
        {
          label: "Välj datum",
          type: "date",
          name: "date",
          value: new Date(),
        },
        {
          label: "Värde",
          type: "number",
          name: "rate",
          value: null,
        },
      ],
      assetID: null,
      date: null,
      rate: null,
      errors: [],
    };
  },

  computed: {
    ...mapState({
      assets: (state) => state.assets.assets,
      notes: (state) => state.notes.notes,
    }),
  },

  mounted() {
    this.$store.dispatch("assets/fetchAssets").then(() => {
      this.assetOptions();
      this.$store.dispatch("notes/fetchNotes");
    });
  },

  methods: {
    assetOptions() {
      if (this.assets.length > 0) {
        this.assets.map((asset) => {
          this.fields[0].options.push({
            text: asset.namn,
            value: asset.tillgangsID,
          });
        });
      }
    },

    async handleSubmit() {
      this.errors = [];
      this.assetID = this.fields[0].value;
      this.date = this.fields[1].value
        ? moment(this.fields[1].value).format("YYYY-MM-DD")
        : null;
      this.rate = this.fields[2].value;

      if (!this.validateForm()) {
        let response = await this.$store.dispatch("notes/addNotes", {
          tillgangsID: this.assetID,
          varde: this.rate,
          datum: this.date,
        });
        if (response) {
          this.fields[0].value = null;
          this.fields[1].value = new Date();
          this.fields[2].value = null;
        }
      }
    },

    findAsset(assetID) {
      if (this.assets.length > 0) {
        let asset = this.assets.find((asset) => asset.tillgangsID === assetID);
        return asset.namn;
      }
    },

    validateForm() {
      let hasError = false;
      if (!this.assetID) {
        hasError = true;
        this.errors.push("Välj tillgång");
      }
      if (!this.date) {
        hasError = true;
        this.errors.push("Välj datum");
      }
      if (!this.rate) {
        hasError = true;
        this.errors.push("Ange värde");
      }
      return hasError;
    },
  },
};
</script>

<style></style>
