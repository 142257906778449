import { $axios } from "@/axios.config";

const state = {
  transactions: [],
};

const mutations = {
  ADD_TRANSACTION(state, payload) {
    state.transactions = payload;
  },
};

const actions = {
  // get.php?type=tillgangsomrade
  fetchTransactions({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=transaktion", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("ADD_TRANSACTION", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addTransaktion med POST-variablerna:
  // typ = antingen 'k'(köp) eller 's'(sälj), användaren väljer
  // datum = en vanlig datepicker där användaren väljer, default är i dag.
  // antal = inmatningsfält för användaren, decimaltal.
  // belopp = inmatningsfält för användaren, decimaltal.
  // tillgangsID = användaren får välja bland de tillgångar som är inmatade på admin, api / get.php ? type = tillgang
  addTransaction({ rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("typ", data.type);
      formData.append("datum", data.selectedDate);
      formData.append("antal", data.quantity);
      formData.append("belopp", data.amount);
      formData.append("tillgangsID", data.assetID);
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addTransaktion", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            dispatch("fetchTransactions");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
