<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Tillgångar" />
      <div class="before-content">
        <inmatning
          :data="fields"
          icon="tillgangar"
          :controller="controller"
          @save="handleSubmit()"
        />
      </div>
      <div class="content p-3">
        <table class="table" v-if="innehavs.length > 0">
          <thead>
            <tr>
              <th scope="col">Namn</th>
              <th scope="col">Antal</th>
              <th scope="col">Köpkurs</th>
              <th scope="col">Förmånsrättskat.</th>
              <th scope="col">Inlösendatum</th>
              <th scope="col">Senaste transaktion</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(innehav, index) in innehavs" :key="index">
              <td>{{ innehav.namn }}</td>
              <td>{{ innehav.antal }}</td>
              <td>{{ innehav.kopkurs }}</td>
              <td>{{ innehav.fmr }}</td>
              <td>{{ innehav.inlosendatum }}</td>
              <td>{{ innehav.startDate }}</td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-secondary" role="alert" v-else>
          <p class="m-0">Inga innehav hittades</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Inmatning from "@/components/Inmatning.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: { Sidebar, Navbar, Inmatning },
  data() {
    return {
      datum: null,
      kolumn: "Innehav",
      fields: [
        {
          label: "Välj datum",
          type: "date",
          name: "datum",
          value: new Date(),
        },
      ],
      rader: [],
      controller: {
        text: "Visas innehav",
        visible: true,
        cancel: false,
        type: "btn-success",
      },
    };
  },

  computed: {
    ...mapState({
      innehavs: (state) => state.innehav.innehavs,
    }),
  },

  mounted() {
    this.datum = moment(this.fields[0].value).format("YYYY-MM-DD");
    if (this.datum) {
      this.$store.dispatch("innehav/fetchInnehavs", this.datum);
    }
  },

  methods: {
    async handleSubmit() {
      this.datum = moment(this.fields[0].value).format("YYYY-MM-DD");
      await this.$store.dispatch("innehav/fetchInnehavs", this.datum);
    },
  },
};
</script>

<style></style>
