<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "@/assets/main.scss";
#app {
  font-family: HelveticaNeue-light, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 100%;
}
</style>
