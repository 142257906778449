<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Transaktioner, Sök" />
      <div class="before-content">
        <inmatning
          :data="fields"
          icon="transaktioner"
          :controller="controller"
          @save="handleSubmit()"
        />
      </div>
      <div class="content p-3">
        <table class="table" v-if="transactions && transactions.length > 0">
          <thead>
            <tr>
              <th scope="col">Tillgång</th>
              <th scope="col">Typ</th>
              <th scope="col">Datum</th>
              <th scope="col">Antal</th>
              <th scope="col">Belopp</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(transaction, index) in transactions" :key="index">
              <td>{{ findAsset(transaction.tillgangsID) }}</td>
              <td>
                <span class="badge bg-info" v-if="transaction.typ === 'k'"
                  >Köp</span
                >
                <span class="badge bg-dark" v-if="transaction.typ === 's'"
                  >Sälj</span
                >
              </td>
              <td>{{ transaction.datum }}</td>
              <td>{{ transaction.antal }}</td>
              <td>
                {{
                  transaction.belopp.toLocaleString("sv-SE", {
                    style: "currency",
                    currency: "SEK",
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Inmatning from "@/components/Inmatning.vue";
import { mapState } from "vuex";
export default {
  name: "emittent",
  components: { Sidebar, Navbar, Inmatning },
  data() {
    return {
      kolumn:
        "Benämning Område Slag Kategori Förvaring Förmångsrättskat. Emittent Hållbarhet Likviditet Inlösendatum",
      fields: [
        {
          label: "Sök",
          type: "text",
          name: "sok",
          value: null,
        },
      ],
      controller: {
        text: "Sök",
        visible: true,
        cancel: false,
        type: "btn-success",
      },
      searchText: null,
    };
  },

  computed: {
    ...mapState({
      assets: (state) => state.assets.assets,
      transactions: (state) => state.transaction.transactions,
    }),
  },

  mounted() {
    this.$store.dispatch("assets/fetchAssets");
    this.$store.dispatch("transaction/fetchTransactions");
  },

  methods: {
    findAsset(assetID) {
      if (this.assets.length > 0) {
        let asset = this.assets.find((asset) => asset.tillgangsID === assetID);
        return asset.namn;
      }
    },

    handleSubmit() {
      this.searchText = this.fields[0].value;
    },
  },
};
</script>
