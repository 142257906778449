import { $axios } from "@/axios.config";

const state = {
  emittents: [],
};

const mutations = {
  ADD_EMITTENT(state, payload) {
    state.emittents = payload;
  },
};

const actions = {
  // get.php?type=emittent
  fetchEmittent({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=emittent", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("ADD_EMITTENT", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addEmittent, skicka med POST-variabel emittent
  addEmittent({ rootState, dispatch }, emittent) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("emittent", emittent);
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addEmittent", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            dispatch("fetchEmittent");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
