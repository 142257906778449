import { $axios } from "@/axios.config";

const state = {
  storage: [],
};

const mutations = {
  ADD_STORAGE(state, payload) {
    state.storage = payload;
  },
};

const actions = {
  // get.php?type=emittent
  fetchStorage({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=forvaring", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("ADD_STORAGE", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addForvaring, skicka med POST-variabel forvaring
  addStorage({ rootState, dispatch }, forvaring) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("forvaring", forvaring);
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addForvaring", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            dispatch("fetchStorage");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
