<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Rapporter, Emittentrisk" />
      <div class="before-content">
        <inmatning
          :data="fields"
          icon="rapporter"
          :controller="controller"
          @save="handleSubmit()"
        />
      </div>
      <div class="v-charts p-3">
        <div class="row">
          <div class="col-md-4" v-if="pieData.length > 0">
            <pie-chart :data="pieData" :labels="pieLables" />
          </div>
        </div>
      </div>
      <div class="content p-3">
        <table class="table" v-if="emittentrisk.length > 0">
          <thead>
            <tr>
              <th scope="col">Emittent</th>
              <th scope="col">Belopp</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(er, index) in emittentrisk" :key="index">
              <td>{{ er.emittent }}</td>
              <td>
                {{
                  er.belopp.toLocaleString("sv-SE", {
                    style: "currency",
                    currency: "SEK",
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-secondary" role="alert" v-else>
          <p class="m-0">Inga innehav hittades</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Inmatning from "../../components/Inmatning.vue";
import PieChart from "@/components/charts/PieChart.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "emittent",
  data() {
    return {
      kolumn: "Emittent",
      fields: [
        {
          label: "Välj datum",
          type: "date",
          name: "datum",
          value: new Date(),
        },
      ],
      rader: [
        "AAK",
        "ABB Ltd",
        "Addtech B",
        "Alfa Laval",
        "Arion Banki SDB",
        "Arjo B",
        "ASSA ABLOY B",
      ],
      controller: {
        text: "Visas rapport",
        visible: true,
        cancel: false,
        type: "btn-success",
      },
      pieData: [],
      pieLables: [],
    };
  },

  computed: {
    ...mapState({
      emittentrisk: (state) => state.report.emittentrisks,
    }),
  },

  components: {
    Sidebar,
    Navbar,
    Inmatning,
    PieChart,
  },

  mounted() {
    this.handleSubmit();
  },

  methods: {
    async handleSubmit() {
      this.datum = moment(this.fields[0].value).format("YYYY-MM-DD");
      if (this.datum) {
        await this.$store.dispatch("report/fetchEmittenrisks", this.datum);
        this.parseData();
      }
    },

    parseData() {
      this.emittentrisk.map((er) => {
        this.pieData.push(er.belopp);
        this.pieLables.push(er.emittent);
      });
    },
  },
};
</script>

<style></style>
