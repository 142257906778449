<template>
  <div class="d-flex bd-highlight">
    <div
      class="p-2 flex-grow-1 bd-highlight"
      v-for="(i, index) in data"
      :key="index"
    >
      <input
        class="form-control"
        :type="i.type"
        :name="i.name"
        step="0.01"
        :placeholder="i.label"
        v-if="i.type != 'select' && i.type !== 'date'"
        :value="i.value"
        @input="i.value = $event.target.value"
        @keyup.enter="$emit('save')"
      />
      <datepicker
        class="form-control"
        :name="i.name"
        :placeholder="i.label"
        :locale="local"
        :upper-limit="lowerLimit"
        v-if="i.type === 'date'"
        v-model="i.value"
        @input="i.value = $event.target.value"
        @keyup.enter="$emit('save')"
      />
      <select
        class="form-select"
        v-if="i.type == 'select'"
        v-model="i.value"
        @input="i.value = $event.target.value"
      >
        <option :value="null" v-if="i.label != null">{{ i.label }}</option>
        <option
          :value="option.value"
          v-for="(option, o) in i.options"
          :key="'o' + o"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
    <div class="p-2 bd-highlight">
      <button
        type="submit"
        class="btn"
        :class="controller.type"
        v-if="controller && controller.visible"
        @click="$emit('save')"
      >
        {{ controller.text }}
      </button>
      <button
        type="submit"
        class="btn btn-secondary"
        v-if="controller && controller.cancel"
        @click="$emit('cancel')"
      >
        Avbryt
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        @click="$emit('save')"
        v-if="!controller"
      >
        Lägg till
      </button>
    </div>
    <div class="p-2 flex-grow-1 bd-highlight">
      <img
        class="nav-logo"
        :src="require('@/assets/' + icon + '.svg')"
        alt
        width="50"
      />
    </div>
  </div>
</template>
<script>
import Datepicker from "vue3-datepicker";
import { sv } from "date-fns/locale";
import { ref } from "vue";
export default {
  name: "inmatning",
  props: {
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    icon: {
      required: true,
      type: String,
      default: null,
    },
    controller: {
      required: false,
      type: Object,
      default: null,
    },
  },
  emits: ["input", "save"],
  data() {
    return {
      local: sv,
      lowerLimit: ref(new Date()),
    };
  },

  components: { Datepicker },

  methods: {
    handleChange(e, selectedIndex) {
      this.$emit("input", { value: e.target.value, index: selectedIndex });
    },
  },
};
</script>

<style lang="scss" scoped>
.bc-row {
  display: inline;
  margin: 5px;
}

.nav-logo {
  float: right;
}
button {
  margin-right: 15px;
}
</style>
