<template>
  <canvas id="pieChart" height="350"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import PieChart from "@/charts/pie-chart.js";
export default {
  props: {
    labels: {
      type: Array,
      required: true,
      default: () => [],
    },

    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  name: "PieChart",
  data() {
    return {
      pieChart: PieChart,
    };
  },

  mounted() {
    this.pieChart.data.labels = this.labels;
    this.pieChart.data.datasets[0].data = this.data;
    const ctx = document.getElementById("pieChart");
    // ctx.width = 250;
    // ctx.height = 250;
    new Chart(ctx, this.pieChart);
  },
};
</script>
