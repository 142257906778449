import { $axios } from "@/axios.config";

const state = {
  emittentrisks: [],
  reports: [],
};

const mutations = {
  FETCH_EMITTENTRISKS(state, payload) {
    state.emittentrisks = payload;
  },

  FETCH_REPORTER(state, payload) {
    state.reports = payload;
  },
};

const actions = {
  fetchEmittenrisks({ commit, rootState }, datum) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      formData.append("datum", datum);
      $axios
        .post(rootState.baseUrl + "/get.php?type=emittentrisk", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_EMITTENTRISKS", response.data.data);
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchReports({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=reports", formData)
        .then((response) => {
          if (response.FormData.status === "ok") {
            commit("FETCH_REPORTER", response.data.data);
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
