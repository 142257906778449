<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Transaktioner, Ny" />
      <div class="before-content">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p class="m-0" v-for="(error, index) in errors" :key="index">
            {{ error }}
          </p>
        </div>
        <inmatning
          :data="fields"
          icon="transaktioner"
          @save="handleSubmit()"
          :controller="controller"
        />
      </div>
      <div class="content p-3">
        <table class="table" v-if="transactions && transactions.length > 0">
          <thead>
            <tr>
              <th scope="col">Tillgång</th>
              <th scope="col">Typ</th>
              <th scope="col">Datum</th>
              <th scope="col">Antal</th>
              <th scope="col">Belopp</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(transaction, index) in transactions" :key="index">
              <td>{{ findAsset(transaction.tillgangsID) }}</td>
              <td>
                <span class="badge bg-info" v-if="transaction.typ === 'k'"
                  >Köp</span
                >
                <span class="badge bg-dark" v-if="transaction.typ === 's'"
                  >Sälj</span
                >
              </td>
              <td>{{ transaction.datum }}</td>
              <td>{{ transaction.antal.toLocaleString("sv") }}</td>
              <td>
                {{
                  transaction.belopp.toLocaleString("sv-SE", {
                    style: "currency",
                    currency: "SEK",
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Inmatning from "@/components/Inmatning.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "emittent",
  components: { Sidebar, Navbar, Inmatning },
  data() {
    return {
      kolumn:
        "Benämning Område Slag Kategori Förvaring Förmångsrättskat. Emittent Hållbarhet Likviditet Inlösendatum",
      fields: [
        {
          label: "Välj tillgång",
          type: "select",
          name: "access",
          options: [],
          value: null,
        },
        {
          label: null,
          type: "select",
          name: "business",
          options: [
            { text: "Köp", value: "k" },
            { text: "Sälj", value: "s" },
          ],
          value: "k",
        },
        {
          label: "Välj datum",
          type: "date",
          name: "date",
          value: new Date(),
        },
        {
          label: "Antal",
          type: "text",
          name: "quantity",
          value: null,
        },
        {
          label: "Belopp",
          type: "text",
          name: "amount",
          value: null,
        },
      ],
      controller: {
        text: "Lägg till",
        visible: true,
        cancel: false,
        type: "btn-success",
      },
      assetID: null,
      business: null,
      date: null,
      quantity: null,
      amount: null,
      errors: [],
    };
  },

  computed: {
    ...mapState({
      assets: (state) => state.assets.assets,
      transactions: (state) => state.transaction.transactions,
    }),
  },

  mounted() {
    this.$store.dispatch("assets/fetchAssets").then(() => {
      this.createAssetOptions();
      this.$store.dispatch("transaction/fetchTransactions");
    });
  },

  methods: {
    createAssetOptions() {
      if (this.assets.length > 0) {
        this.assets.map((assest) => {
          this.fields[0].options.push({
            text: assest.namn,
            value: assest.tillgangsID,
          });
        });
      }
    },

    findAsset(assetID) {
      if (this.assets.length > 0) {
        let asset = this.assets.find((asset) => asset.tillgangsID === assetID);
        return asset.namn;
      }
    },

    async handleSubmit() {
      this.errors = [];
      this.assetID = this.fields[0].value;
      this.business = this.fields[1].value;
      this.date = this.fields[2].value
        ? moment(this.fields[2].value).format("YYYY-MM-DD")
        : null;
      this.quantity = this.fields[3].value;
      this.amount = this.fields[4].value;

      if (!this.validateForm()) {
        let response = await this.$store.dispatch(
          "transaction/addTransaction",
          {
            type: this.business,
            selectedDate: this.date,
            quantity: this.quantity,
            amount: this.amount,
            assetID: this.assetID,
          }
        );

        if (response) {
          this.reset();
        }
      }
    },

    validateForm() {
      let hasError = false;
      if (!this.assetID) {
        hasError = true;
        this.errors.push("Välj tilgång");
      }
      if (!this.business) {
        hasError = true;
        this.errors.push("Välj affär");
      }
      if (!this.date) {
        hasError = true;
        this.errors.push("Välj datum");
      }
      if (!this.quantity) {
        hasError = true;
        this.errors.push("Ange antal");
      }
      if (!this.amount) {
        hasError = true;
        this.errors.push("Ange belopp");
      }
      return hasError;
    },

    reset() {
      this.errors = [];
      this.fields[0].value = null;
      this.fields[1].value = "k";
      this.fields[2].value = new Date();
      this.fields[3].value = null;
      this.fields[4].value = null;
    },
  },
};
</script>

<style></style>
