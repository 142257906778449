import { $axios } from "@/axios.config";

const state = {
  omrade: [],
};

const mutations = {
  ADD_OMRADE(state, payload) {
    state.omrade = payload;
  },
};

const actions = {
  // get.php?type=tillgangsomrade
  fetchOmrade({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=tillgangsomrade", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("ADD_OMRADE", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addTillgangsomrade, skicka med POST-variabel omrade
  addOmrade({ rootState, dispatch }, omrade) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("omrade", omrade);
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addTillgangsomrade", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            dispatch("fetchOmrade");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
