import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import axios from "axios";
import "bootstrap";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// const base = axios.create({
//   baseURL: process.env.VUE_APP_URL,
// });

const app = createApp(App);

// app.config.globalProperties.$http = base;
app.config.productionTip = false;

app.use(VueSweetalert2);
app.use(store);
app.use(router);
app.mount("#app");
