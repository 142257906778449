<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Rapporter, Förmånsrättstillgångar" />
      <div class="before-content">
        <inmatning :data="inmatning" icon="rapporter" />
      </div>
      <div class="content p-3">
        <admintabell :kolumn="kolumn" :rader="rader" />
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Admintabell from "@/components/Admintabell.vue";
import Inmatning from "../../components/Inmatning.vue";
import { mapState } from "vuex";
export default {
  name: "emittent",
  data() {
    return {
      kolumn: "Emittent",
      inmatning: [
        {
          label: "Emittentrisk",
          type: "date",
          name: "emittentrisk",
        },
      ],
      rader: [
        "AAK",
        "ABB Ltd",
        "Addtech B",
        "Alfa Laval",
        "Arion Banki SDB",
        "Arjo B",
        "ASSA ABLOY B",
      ],
    };
  },

  computed: {
    ...mapState({
      reports: (state) => state.report.reports,
    }),
  },

  components: { Sidebar, Navbar, Admintabell, Inmatning },

  mounted() {
    console.log(this.reports);
  },

  methods: {},
};
</script>

<style></style>
