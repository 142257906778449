<template>
  <div class="navbar-holder">
    <nav class="navbar navbar-expand-lg navbar-light px-3">
      <div class="d-flex bd-highlight align-items-center w-100">
        <div class="p-2 flex-grow-1 bd-highlight fw-bold">{{ title }}</div>
        <div class="p-2 bd-highlight v-right">
          <button
            class="btn btn-default text-right"
            @click="showDropdown()"
            @blur="collapsed = false"
          >
            <i class="bi bi-person-circle"></i>
            <span>{{ realname }}</span>
            <i class="bi bi-chevron-down"></i>
          </button>
          <div class="v-dropdown" :class="{ collapse: collapsed }">
            <ul>
              <!-- <li>
                  <i class="bi bi-sliders"></i>
                  <span>Inställningar</span>
                </li>
                <li>
                  <i class="bi bi-share"></i>
                  <span>Uppföljningar</span>
                </li> -->
              <li @click="logout()">
                <i class="bi bi-box-arrow-right"></i>
                <span>Logga ut</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      collapsed: true,
    };
  },

  computed: {
    ...mapState({
      realname: "realname",
    }),
  },

  mounted() {
    document.addEventListener("click", this.close);
  },

  unmounted() {
    document.removeEventListener("click", this.close);
  },

  methods: {
    showDropdown() {
      this.collapsed = !this.collapsed;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.collapsed = true;
      }
    },

    async logout() {
      await this.$store.dispatch("auth/authLogout");
      this.$store.commit("LOGOUT_AUTH");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-holder {
  background: #fff;
  height: 79px;
  border-bottom: 1px solid #ddd;
  position: relative;

  .title {
    font-weight: 600;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    color: #5b5a65;
    font-size: 17px;
  }
  .nav-item {
    font-size: 17px;
    letter-spacing: 1px;
    color: #000;
  }
  .nav-user {
    color: #8b8d91;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.5;
    margin-top: 15px;
  }
  .user-img {
    margin-top: 10px;
  }
  .v-right {
    max-width: 300px;
    // text-align: right;
    .bi-person-circle {
      font-size: 26px;
      margin-right: 10px;
    }
    .bi-chevron-down {
      margin-left: 10px;
    }
    button {
      display: flex;
      align-items: center;
      font-weight: 600;
      color: #707b7c;
      &:hover {
        font-weight: 600;
        color: #bdc3c7;
      }
    }
    .v-dropdown {
      position: absolute;
      background: #f2f3f4;
      margin-top: 10px;
      // padding: 15px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      border-bottom-left-radius: 5px;
      width: 250px;
      ul {
        padding-left: 0;
        li {
          list-style: none;
          border-bottom: 1px solid #ddd;
          padding: 10px 15px;
          cursor: pointer;
          .bi {
            margin-right: 10px;
          }
          &:hover {
            background: #ebedef;
          }
        }
      }
    }
    // text-align: right;
  }
}
</style>
