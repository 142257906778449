<template>
  <main>
    <sidebar />
    <div class="start w-100">
      <navbar title="Administration, Förmånsrättskategori" />
      <div class="before-content">
        <inmatning
          :data="fields"
          icon="admin"
          @save="handleSubmit()"
          :controller="controller"
        />
      </div>
      <div class="content p-3">
        <table class="table" v-if="benefits && benefits.length > 0">
          <thead>
            <tr>
              <th scope="col">Förmånsrättskategori</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(benefit, index) in benefits" :key="index">
              <td>{{ benefit.fmr }}</td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-secondary" role="alert" v-else>
          <p class="m-0">Ingen förmånsrättskategori hittades</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Inmatning from "@/components/Inmatning.vue";
import { mapState } from "vuex";
export default {
  name: "emittent",
  components: { Sidebar, Navbar, Inmatning },
  data() {
    return {
      kolumn: "-",
      fields: [
        {
          label: "Förmånsrättskategori",
          type: "text",
          name: "",
        },
      ],
      controller: {
        text: "Lägg till",
        visible: true,
        cancel: false,
        type: "btn-success",
      },
    };
  },

  computed: {
    ...mapState({
      benefits: (state) => state.fmr.benefits,
    }),
  },

  mounted() {
    this.$store.dispatch("fmr/fetchFMR");
  },

  methods: {
    async handleSubmit() {
      let benefitCategory = this.fields[0].value;
      if (benefitCategory) {
        await this.$store.dispatch("fmr/addFMR", benefitCategory);
        this.fields[0].value = null;
      }
    },
  },
};
</script>

<style></style>
