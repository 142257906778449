<template>
  <nav id="sidebar" class="sidebar d-flex flex-column flex-shrink-0">
    <div class="side-header">
      <h4 style="color: #3d83fd">
        Finans<span style="color: #1a1a1a">modulen 0.0.2</span>
      </h4>
      <router-link to="/">
        <img class="nav-logo" src="@/assets/logo.svg" alt />
      </router-link>
    </div>
    <ul class="nav nav-pills flex-column mb-auto">
      <li
        class="nav-item"
        :class="{ active: menu.name === parent }"
        v-for="(menu, index) in menus"
        :key="index"
        @click="onItemClick(index)"
      >
        <router-link
          :to="menu.path"
          class="nav-link link-dark"
          v-if="!menu.children"
          @click="toggleNavbar(menu)"
        >
          <i class="bi" :class="menu.icon"></i>
          <span class="link-dark">{{ menu.title }}</span>
        </router-link>
        <button
          class="btn btn-default d-flex justify-content-between"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
          v-else
          @click="toggleNavbar(menu)"
        >
          <span class="link-dark">
            <i class="bi" :class="menu.icon"></i>{{ menu.title }}
          </span>
          <i class="bi bi-chevron-down" v-if="menu.collapsed"></i>
          <i class="bi bi-chevron-up" v-else></i>
        </button>
        <div
          class="collapse"
          :class="{ active: !menu.collapsed || menu.name === parent }"
          v-if="menu.children && menu.children.length > 0"
        >
          <ul class="btn-toggle-nav list-unstyled">
            <li
              v-for="(child, index) in menu.children"
              :key="index"
              :class="{ selected: child.name === navname }"
            >
              <router-link
                :to="{ name: child.name }"
                class="link-dark"
                @click="selectedChild(child)"
              >
                {{ child.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import Menus from "@/assets/menus.json";
import { mapState } from "vuex";
export default {
  name: "sidbar",
  data() {
    return {
      navname: null,
      menus: Menus,
    };
  },

  computed: {
    ...mapState({
      parent: "parent",
      child: "child",
    }),
  },

  mounted() {
    this.navname = this.$route.name;
  },

  methods: {
    toggleNavbar(menu) {
      // this.$store.commit("SET_MENU", menu.name);
      localStorage.setItem("_fm_parent", JSON.stringify(menu.name));
      if (
        menu.name === "Administration" ||
        menu.name === "Transactions" ||
        menu.name === "Reports"
      ) {
        menu.collapsed = !menu.collapsed;
      } else if (menu.name === "Logout") {
        this.$store.commit("SET_MENU", menu.name);
        this.$swal({
          title: "Vill du verkligen logga ut?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ja, logga ut!",
          cancelButtonText: "Avbryt",
        }).then((result) => {
          if (result.isConfirmed) {
            // Call http request to delete
            this.logout();
          }
        });
      }
    },

    async logout() {
      await this.$store.dispatch("auth/authLogout");
      this.$store.commit("LOGOUT_AUTH");
      this.$router.push({ name: "Login" });
    },

    onItemClick(selectedIndex) {
      this.menus.forEach((menu, index) => {
        if (index !== selectedIndex) {
          menu.collapsed = true;
        } else {
          menu.collapsed = !menu.collapsed;
          this.$store.commit("SET_MENU", menu.name);
        }
      });
    },

    selectedChild(menu) {
      localStorage.setItem("_fm_child", JSON.stringify(menu.name));
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background: white;
  color: #5c5c5c;
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
  border-right: 1px solid #ddd;
  border-left: 5px solid #e7f2ff;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
  letter-spacing: 1px;

  .side-header {
    background-color: white;
    min-height: 79px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 0.35rem;
    // border-top: 5px solid #e7f2ff;
    // border-bottom: 5px solid #e7f2ff;
    margin-bottom: 25px;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #f1f1f1;

    h4 {
      margin: 0;
      padding: 0;
      font-size: 16px;
      text-transform: none;
      letter-spacing: 2px;
      font-weight: 300;
    }

    h5 {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }

  ul {
    color: #2c3e50;
    .bi {
      margin-right: 10px;
      margin-left: 5px;
      font-size: 16px;
    }
    .components {
      padding: 20px 0;
      border-bottom: 1px solid #47748b;
    }
    li {
      .collapse {
        display: none;
      }
      .collapse.active {
        display: block;
        .selected a {
          color: #3d83fc;
        }
      }
      a {
        padding: 10px 0px 10px 10px;
        display: block;
        position: relative;
        color: #5a5a5a;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover {
          background: #e8edf3;
          border-right: 5px solid #3d83fc;
          border-radius: 0;
          cursor: pointer;
        }
        .menu-icon {
          display: inline-block;
          width: 1.25em;
          margin-right: 0.8em;
          font-size: 1.125rem;
          color: black;
        }
        .link-dark {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          letter-spacing: 1px;
          margin-left: 3px;
        }
        .caret-up,
        .caret-down {
          position: absolute;
          right: 20px;
          opacity: 0.8;
        }
      }
      button {
        text-align: left;
        padding: 10px 0px 10px 10px;
        width: 100%;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background: #e8edf3;
          border-right: 5px solid #3d83fc;
          border-radius: 0;
          cursor: pointer;
        }
      }
      ul {
        .link-dark {
          font-weight: 300;
          text-decoration: none;
          letter-spacing: 1px;
          padding-left: 35px;
        }
        background: #f4f7f9;
        li {
          a {
            font-size: 0.85em !important;
            font-weight: 600;
          }
          &:hover {
            a {
              border-right: 5px solid #3d83fc;
              background: #e8edf3;
            }
          }
        }
        li.active {
          a {
            font-weight: 600;
          }
        }
      }
    }
    li.active {
      background: #e5e5e5;
      .menu-icon,
      .link-dark {
        color: #535353;
      }
      ul {
        display: block;
      }
    }
  }

  a[data-toggle="collapse"] {
    position: relative;
  }

  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
#sidebar.hidden {
  margin-left: -250px;
}
.nav-ico {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 3px;
  width: 16px;
}
</style>
