import { createRouter, createWebHistory } from "vue-router";
import Start from "@/views/Start.vue";
import MainIndex from "@/views/MainIndex.vue";
import Emittent from "@/views/administration/Emittent.vue";
import Formansrattskategori from "@/views/administration/Formansrattskategori.vue";
import Forvaring from "@/views/administration/Forvaring.vue";
import Hallbarhet from "@/views/administration/Hallbarhet.vue";
import Likviditet from "@/views/administration/Likviditet.vue";
import Tillgangar from "@/views/administration/Tillgangar.vue";
import Tillgangskategori from "@/views/administration/Tillgangskategori.vue";
import Tillgangsomrade from "@/views/administration/Tillgangsomrade.vue";
import Tillgangsslag from "@/views/administration/Tillgangsslag.vue";
import Ny from "@/views/transaktioner/Ny.vue";
import Sok from "@/views/transaktioner/Sok.vue";
import Innehav from "@/views/Innehav.vue";
import Noteringar from "@/views/Noteringar.vue";
import RapportEmittentrisk from "@/views/rapporter/RapportEmittentrisk.vue";
import Formansrattstillgangar from "@/views/rapporter/Formansrattstillgangar.vue";
import RapportLikviditet from "@/views/rapporter/RapportLikviditet.vue";
import RapportTillgangar from "@/views/rapporter/RapportTillgangar.vue";
import Tillgangsslagsfordelning from "@/views/rapporter/Tillgangsslagsfordelning.vue";
import store from "@/store/index";
import Login from "@/views/Login.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Start,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Administration",
    component: MainIndex,
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/emittent",
        name: "Emittent",
        component: Emittent,
      },
      {
        path: "/formansrattskategori",
        name: "Formansrattskategori",
        component: Formansrattskategori,
      },
      {
        path: "/forvaring",
        name: "Forvaring",
        component: Forvaring,
      },
      {
        path: "/hallbarhet",
        name: "Hallbarhet",
        component: Hallbarhet,
      },
      {
        path: "/likviditet",
        name: "Likviditet",
        component: Likviditet,
      },
      {
        path: "/tillgangar",
        name: "Tillgangar",
        component: Tillgangar,
      },
      {
        path: "/tillgangskategori",
        name: "Tillgangskategori",
        component: Tillgangskategori,
      },
      {
        path: "/tillgangsomrade",
        name: "Tillgangsomrade",
        component: Tillgangsomrade,
      },
      {
        path: "/tillgangsslag",
        name: "Tillgangsslag",
        component: Tillgangsslag,
      },
    ],
  },
  {
    path: "/transaktioner",
    name: "Transactions",
    component: MainIndex,
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/ny",
        name: "Ny",
        component: Ny,
      },
      {
        path: "/sok",
        name: "Sok",
        component: Sok,
      },
    ],
  },
  {
    path: "/rapporter",
    name: "Reports",
    component: MainIndex,
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/emittenrisk",
        name: "RapportEmittentrisk",
        component: RapportEmittentrisk,
      },
      {
        path: "/formansrattstillgangar",
        name: "Formansrattstillgangar",
        component: Formansrattstillgangar,
      },
      {
        path: "/likviditet",
        name: "RapportLikviditet",
        component: RapportLikviditet,
      },
      {
        path: "/tillgangar",
        name: "RapportTillgangar",
        component: RapportTillgangar,
      },
      {
        path: "/tillgangsslagsfordelning",
        name: "Tillgangsslagsfordelning",
        component: Tillgangsslagsfordelning,
      },
    ],
  },

  {
    path: "/innehav",
    name: "Innehav",
    component: Innehav,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/noteringar",
    name: "Notes",
    component: Noteringar,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/logout",
    name: "Logout",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authenticated = store.state.authenticated;
  if (to.matched.some((record) => record.meta.requiresAuth) && !authenticated) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else if (to.name === "Login" && authenticated) {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;
