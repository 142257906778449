import { $axios } from "@/axios.config";

const state = {
  categories: [],
};

const mutations = {
  ADD_CATEGORY(state, payload) {
    state.categories = payload;
  },
};

const actions = {
  // get.php?type=emittent
  fetchCategory({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=tillgangskategori", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("ADD_CATEGORY", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addTillgangskategori, skicka med POST-variabel kategori
  addCategory({ rootState, dispatch }, kategori) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("kategori", kategori);
      formData.append("key", rootState.apiKey);
      $axios
        .post(
          rootState.baseUrl + "/edit.php?type=addTillgangskategori",
          formData
        )
        .then((response) => {
          if (response.data.status == "ok") {
            dispatch("fetchCategory");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
