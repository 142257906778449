import { $axios } from "@/axios.config";

const state = {
  notes: [],
};

const mutations = {
  FETCH_NOTES(state, payload) {
    state.notes = payload;
  },
};

const actions = {
  // api/get.php?type=notering
  fetchNotes({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/get.php?type=notering", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_NOTES", response.data.data);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Lägg till noteringar:
  // edit.php?type=addNotering med POST- variablerna:
  // tillgangsID = användaren får välja bland de tillgångar som är inmatade på admin, api/get.php?type=tillgang varde = inmatningsfält för användaren, decimaltal. datum = en vanlig datepicker där användaren väljer, default är i dag.
  addNotes({ rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("tillgangsID", data.tillgangsID);
      formData.append("varde", data.varde);
      formData.append("datum", data.datum);
      formData.append("key", rootState.apiKey);
      $axios
        .post(rootState.baseUrl + "/edit.php?type=addNotering", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchNotes");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
